import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getFullImageUrl } from 'client/utils/image-helpers';
import { IMAGE_SIZES } from 'site-modules/shared/constants/editorial/editorial';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { Carousel } from 'site-modules/shared/components/carousel/carousel';

import './article-photo.scss';

function Slide({ photo, showLineUnderTitle, aspectRatio, isLazyImage, ...restProps }) {
  return (
    <div {...restProps}>
      <AspectRatioContainer aspectRatio={aspectRatio}>
        <img
          alt={photo.title || photo.captionTranscript || ''}
          className="w-100"
          src={getFullImageUrl(photo.href, IMAGE_SIZES.lg)}
          loading={isLazyImage ? 'lazy' : undefined}
        />
      </AspectRatioContainer>
      {!!photo.captionTranscript && (
        <p className={classnames('xsmall mb-0 py-0_5', { 'title-line': showLineUnderTitle })}>
          {photo.captionTranscript}
        </p>
      )}
    </div>
  );
}

Slide.propTypes = {
  photo: PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
    captionTranscript: PropTypes.string,
  }).isRequired,
  showLineUnderTitle: PropTypes.bool,
  aspectRatio: PropTypes.string,
  isLazyImage: PropTypes.bool,
};

Slide.defaultProps = {
  showLineUnderTitle: true,
  aspectRatio: '3:2',
  isLazyImage: false,
};

export function ArticlePhoto({ photos, className, showLineUnderTitle, aspectRatio, isLazyImages }) {
  if (!photos.length) {
    return null;
  }

  const firstPhotoSlide = <Slide photo={photos[0]} showLineUnderTitle={showLineUnderTitle} aspectRatio={aspectRatio} />;
  return (
    <div className={classnames('article-photo', className)}>
      {// Render carousel in a case we have several photos
      // or Render single slide image in a case of only one photo
      photos.length > 1 ? (
        <ClientSideRender placeholder={firstPhotoSlide}>
          {/* Do not set infinite to true since it's affecting SEO with cloned slides */}
          <Carousel infinite={false}>
            {photos.map(photo => (
              <Slide
                key={photo.href}
                photo={photo}
                showLineUnderTitle={showLineUnderTitle}
                aspectRatio={aspectRatio}
                isLazyImage={isLazyImages}
              />
            ))}
          </Carousel>
        </ClientSideRender>
      ) : (
        firstPhotoSlide
      )}
    </div>
  );
}

ArticlePhoto.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
      captionTranscript: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  showLineUnderTitle: PropTypes.bool,
  aspectRatio: PropTypes.string,
  isLazyImages: PropTypes.bool,
};

ArticlePhoto.defaultProps = {
  photos: [],
  className: '',
  showLineUnderTitle: true,
  aspectRatio: undefined,
  isLazyImages: false,
};
